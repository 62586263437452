<template>
    <div class="text-truncate" :style="{'max-width': width}">
        <v-tooltip top>
            <template #activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on">{{ text }}</span>
            </template>
            <span>{{ text }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
        width: {
            type: String,
            default: '25vw'
        }
    }
}
</script>

<style scoped>
.text-truncate {
    text-overflow: ellipsis;
}
</style>